<template>
  <cui-acl :roles="['talkmoreB2Csell']" redirect>
    <div class="kit__utils__heading">
      <h5>
        <span>Talkmore Privat TM Produkter</span>
      </h5>
    </div>
      <h5 class="mb-3">
        Produkter
      </h5>
    <a-table bordered :data-source="dataSource" :columns="columns" :pagination="{ pageSize: 50, hideOnSinglePage: true }">
    </a-table>
    <!-- <a-divider></a-divider>
    <h5>
      Kampanjer
    </h5>
    <a-table bordered :data-source="campaigns" :columns="campaignColumns">

    </a-table> -->
  </cui-acl>
</template>
<script>
import cuiAcl from '@/components/cleanui/system/ACL'
import { firestore } from '@/services/firebase'
import { mapState } from 'vuex'
import exportFromJSON from 'export-from-json'

function onChange(pagination, filters, sorter) {
  console.log('params', pagination, filters, sorter)
}

export default {
  name: 'TablesAntdFilterSorter',
  computed: {
    ...mapState(['user']),
  },
  components: {
    cuiAcl,
  },
  data() {
    return {
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      dataSource: [],
      count: 0,
      columns: [
        {
          title: 'Produktnavn',
          dataIndex: 'name',
        },
        {
          title: 'Provisjon',
          dataIndex: 'comission',
        },
        {
          title: 'Poeng',
          dataIndex: 'points',
        },
      ],
    }
  },
  methods: {
    onChange,
    onCellChange(key, dataIndex, value) {
      const dataSource = [...this.dataSource]
      const target = dataSource.find(item => item.key === key)
      if (target) {
        target[dataIndex] = value
        this.dataSource = dataSource
        firestore.collection('tmb2c-products').doc(target.id)
          .set({
            name: target.name,
            points: Number(target.points),
            sequence: Number(target.sequence),
            status: target.status,
            family: Boolean(target.family === 'true'),
            type: target.type,
          })
        this.$forceUpdate()
      }
    },
    onDelete(key) {
      const dataSource = [...this.dataSource]
      const target = dataSource.find(obj => obj.key === key)
      console.log(target)
      firestore.collection('tmb2c-products').doc(target.id).delete()
      this.dataSource = dataSource.filter(obj => obj.key !== key)
      this.$forceUpdate()
    },
    handleAdd() {
      const { dataSource } = this
      this.count = this.count + 1
      const newData = {
        key: this.count,
        name: `Ny Produkt ${this.count + 1}`,
        type: 'main',
        family: 'false',
        points: 0,
        status: 'inactive',
      }
      firestore.collection('tmb2c-products').add(newData)
        .then(res => {
          const fireData = newData
          fireData.id = res.id
          fireData.points = String(fireData.points)
          this.dataSource = [...dataSource, fireData]
          console.log(fireData)
          this.$forceUpdate()
        })
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    downloadData() {
      const data = this.dataSource
      const fileName = 'product data'
      const exportType = exportFromJSON.types.csv
      const delimiter = ';'
      exportFromJSON({ data, fileName, exportType, delimiter })
    },
  },
  mounted() {
    // create a search bar on top to filter any value for user const res = arr.filter(obj => Object.values(obj).some(val => val.includes(search)));
    firestore.collection('tmb2c-products').where('status', '==', 'active').orderBy('name', 'asc')
      .get().then(snapshot => {
        this.dataSource = []
        this.count = 0
        snapshot.forEach(doc => {
          this.dataSource.push({
            key: this.count,
            id: String(doc.id),
            name: String(doc.data().name),
            points: String(doc.data().points),
            comission: Number(doc.data().comission),
            sequence: Number(doc.data().sequence),
          })
          this.count += 1
        })
        // order all objects by sequence. if there is no sequence, put it at the end
        this.dataSource = this.dataSource.sort((a, b) => {
          if (a.sequence === undefined) {
            return 1
          }
          if (b.sequence === undefined) {
            return -1
          }
          return a.sequence - b.sequence
        })
        return firestore.collection('tmb2c-campaigns').get()
      })
  },
}
</script>
